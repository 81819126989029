import { Routes, Route } from "react-router-dom";
import Download from "./Pages/Download";
import Privacy from "./Pages/Privacy";
import Terms from "./Pages/Terms";

import ScrollToTop from "./Component/ScrollToTop";

function App() {
	return (
		<ScrollToTop>
			<Routes>
				<Route path="/" element={<Download />} />
				<Route path="/privacy" element={<Privacy />} />
				<Route path="/terms" element={<Terms />} />
			</Routes>
		</ScrollToTop>
	);
}

export default App;
