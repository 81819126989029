export const ABOUT_US_DETAILS = [
    {
      title: "ABOUT THE SERVICE",
      des: [
        `The Service primarily consists of a downloadable mobile phone application for accessing menu of alcoholic and non-alcoholic drinks (the “Products”) marketed and provided by nightclubs and lounges for their customers to order and pay. The App is a marketing platform for nightclub and lounge business owners to create menu items specific to their business operations to offer said drinks for customers to access and pay for. `,
        `We are not in the business of selling and/or distributing alcohol.  Please be advised that the bar, club, lounge or any establishment where You purchase the alcohol is the seller of those services and shall be liable for such sale and distribution.`,
        
      ],
    },
   
    {
      title: "PRIVACY POLICY",
      des:` TDA respects the privacy of its Service users.  Please refer to TDA’s Privacy Policy located at www.drinkapp.us, which explains how we collect, use, and disclose information that pertains to Your privacy.  When You access or use the Service, You signify Your agreement to this Privacy Policy.`,
    },
    {
        title: "REGISTRATION DATA",
        des: [
          `Using the App, You will be asked to create Your own personalized account. In consideration of Your use of the Services, You agree to (a) provide accurate, current and complete information about You as may be prompted by any registration forms made available via the Service ("Registration Data"); (b) maintain the security of Your password and identification; (c) maintain and timely update the Registration Data, and any other information You provide to TDA, to keep it accurate, current and complete; and (d) accept all risks of unauthorized access to the Registration Data and any other information You provide to TDA.`,
          `You may not use the Service if You are less than 21 years of age or are less than the legal drinking age of the state, country or jurisdiction in which you are using TDA’s Services, the App, the Products and/or other services referenced herein (the “Legal Drinking Age”) and all of the members in your group are also 21 years of age or Legal Drinking Age. By using the App, You verify that You are entering into this Agreement with a clear mind, not under duress, and in an un-intoxicated state and You are either 21 years of age or Legal Drinking Age..`,
          `TDA is under no obligation to retain a record of Your account or any data or information You may have stored by means of the account or Your use of the Application and Services. You are only authorized to create and use one account for the Service. You are under no obligation to use or continue to use the Application or Services and You may cease use of the Application or Services without notice to TDA.`
        ],
      },
      {
        title: "LICENSE GRANT",
        des: [
          `Subject to the terms, conditions and limitations set forth in these Terms, TDA hereby grants You a non-exclusive, non-transferable and revocable license to access and use the Service for Your personal, non-commercial use only, except as otherwise specifically permitted under a written agreement entered into with TDA. This license does not allow You to use the App on any mobile device that You do not own or control, and You may not distribute or make the App available over a network where it could be used by multiple devices at the same time. You may not rent, lease, lend, sell, redistribute or sublicense the Service. You may not copy (except as expressly permitted by this license and the Usage Rules), decompile, reverse engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Service, any updates, or any part thereof (except as and only to the extent any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the licensing terms governing use of any open sourced components included with the Service). You may not remove, alter or obscure any copyright, trademark or other proprietary rights notice on or in the App or the Website. Any attempt to do so is a violation of our rights. If You breach any of the foregoing restrictions Your right to use the Services will immediately cease, You will have violated our rights and may be subject to prosecution and damages. The terms of the license will govern any upgrades provided by us that replace and/or supplement the original Service, unless such upgrade is accompanied by a separate license in which case the terms of that license will govern. `,
          `You acknowledge and agree that Your use of the Service must also be in accordance with the usage rules established by Your mobile device platform or service provider.`,
          
        ],
    },
    {
        title: "CONSENT TO USE OF DATA AND MOBILE COMMUNICATIONS",
        des:` You agree that TDA may collect and use technical data, personal information and related information in connection with Your use of the Application including, but not limited to, contact information and technical information about Your use, the device, system, software, and peripherals, that are gathered to analyze the use, features and functionality of the App and to facilitate the provision of software updates, product support and other services related to the App. TDA is also authorized to contact You about our Services through the App or by using the contact information provided by You.`,
      },
      {
        title: "PRODUCT DESCRIPTIONS AND REFUNDS",
        des: [
          `TDA endeavors to be as accurate as possible when describing the Products marketed and provided in the App by lounges and nightclubs. However, TDA does not warrant that the Service or the Products are complete, reliable, current or error-free. If You have reason to believe that a Product You purchased through the Service does not meet the claims advertised within the Service, Your sole remedy is to not accept the Product and contact TDA immediately at DrinkApp2020@gmail.com, and in any case no later than seven (7) business days following the date of purchase. TDA will make a determination as to the merits of Your claim in our sole discretion. `,
          `If You purchased a Product from a nightclub or lounge through the App, it is the nightclub or lounges responsibility to provide the Product You purchased. This is our agreement with each venue. We will endeavor to enforce our agreements with nightclubs and lounges, and while we are not responsible to You if the nightclub or lounge does not, for whatever reason, provide the purchased Product to You, we ask that You contact us immediately at our email address DrinkApp2020@gmail.com in the event that the nightclub or lounge is unable or unwilling to provide the purchased Product to You, so that we may assist in rectifying the situation. In any case, You have no right to a refund from us. However, we may, in our sole discretion, refund the purchase price of the Product or a portion thereof.`,
          
        ],
      },
      {
        title: "Third Party Content",
        des: [
          `TDA may display, include or make available content, data, information, applications or materials from third parties (collectively the "Third Party Content"). TDA does not control, warrant, endorse or adopt any Third Party Content and does not assume and will not have any liability or responsibility to You or any other person for any third-party services, Third Part Content, or web sites, or for any other materials, products, or services of third parties. You acknowledge and agree that TDA is not responsible or liable in any manner for any Third Party Content and is not responsible for examining or evaluating the content, accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect of such Third Party Materials or web sites. Users use such Third Party Content contained therein at their own risk.`,
          `TDA may also run advertisements and promotions from third parties on the Website or in the App or may otherwise provide information about or links to other third-party products or services. Any purchases or other transactions or actions made on or through links accessed from the Website or App are solely between the user and the third party from whom the purchase was made (the "Third-Party Seller"). Any business dealings, interactions or correspondence with, or participation in promotions of such Third Party Seller, and any terms, conditions, warranties or representations associated with such purchases, dealings or promotions, are also solely between the user and such Third-Party Seller. TDA is not responsible or liable for any loss or damage of any sort incurred as the result of any such third-party purchases, dealings, interactions, advertising or promotions or as the result of the presence of such third party information or content on the Website or in the App.`,
          `You understand that by using any of the Services or Third Party Content, You may encounter content that may be deemed offensive, indecent, or objectionable, which content may or may not be identified as having explicit language, and that the results of any search or entering of a particular URL may automatically and unintentionally generate links or references to objectionable material. Nevertheless, You agree to use the Services at Your sole risk and that the Application Provider shall not have any liability to You for content that may be found to be offensive, indecent, or objectionable.`
        ],
      },
      {
        title: "COMPATIBILITY WITH MOBILE DEVICES",
        des:` TDA does not warrant that the App will be compatible or interoperable with Your mobile device or any other hardware, software or equipment installed on or used in connection with Your mobile device.`,
      },
      {
        title: "PAYMENT FOR PRODUCTS",
        des: [
          `When You make a purchase within the App You are representing to us that: (i) any credit information You supply is true and complete; (ii) charges incurred by You will be honored by Your credit card company; (iii) You will pay the charges incurred by You at the posted prices, including any applicable taxes, and (iv) that You agree to any terms, conditions and privacy policies of the third party payment service. `,
          `In connection with Your payment for Products within the App, You acknowledge that: (a) TDA is using a third party payment service that is integrated into the App, (b) TDA is not responsible for any error by the third party payment service, (c) the posted prices for the Products include the rate negotiated by TDA with nightclub and lounges including service fees and taxes, where applicable, and are deemed part of this Agreement; and (d) TDA retains a service fee from the posted price as compensation in servicing Your Product purchase, regardless of whether You are able to consummate the Product purchase.`,
          
        ],
    },
    {
        title: "ELECTRONIC COMMUNICATIONS",
        des:`When You use the Service, or send e-mails to us, You are communicating with us electronically. As such, You consent to receive communications from us electronically. We will communicate with You via e-mail, mobile telephone, or through the App. You agree that all agreements, notices, disclosures and other communications that we provide to You electronically satisfy any legal requirement that such communications be in writing.`,
      },
      {
        title: "CARRIER CHARGES",
        des:`You acknowledge and understand that the Service requires and utilizes mobile phone service, data access and text messaging capabilities. Carrier rates for phone, data and text messaging may apply and You are responsible for any such charges.`,
      },
      {
        title: "INTELLECTUAL PROPERTY",
        des: [
          `TDA, the TDA logos and any other product or service name or slogan contained in the Website and App are trademarks of TDA and its suppliers or licensors, and may not be copied, imitated or used, in whole or in part, without the prior written permission of TDA or the applicable trademark holder. You may not use any metatags or any other "hidden text" utilizing “The Drink App” or "TDA" or any other name, trademark or product or service name of TDA without our prior written permission. In addition, the look and feel of the Website and the App, including all page headers, custom graphics, button icons and scripts, is intellectual property of TDA and may not be copied, imitated or used, in whole or in part, without our prior written permission. All other trademarks, registered trademarks, product names and company names or logos mentioned on the Website or in the App are the property of their respective owners. Reference to any products, services, processes or other information, by trade name trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by us.`,
          `You acknowledge and agree that we and our licensors retain ownership of all intellectual property rights of any kind related to the Service, including applicable copyrights, trademarks and other proprietary rights.  Other product and company names that are mentioned on the Service may be trademarks of their respective owners. We reserve all rights that are not expressly granted to You under this Agreement.`,
          `You may not, and You will not encourage, assist or authorize any other person to copy, modify, reverse engineer, decompile or disassemble, or otherwise tamper with, the App, whether in whole or in part, or create any derivative works from or of the App.`
        ],
      },
      {
        title: "NO WARRANTY",
        des:`YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE SERVICE AND PRODUCTS IS AT YOUR SOLE RISK AND THAT THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH YOU. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES AND PRODUCTS ARE PROVIDED "AS IS" AND “AS AVAILABLE”, WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND TDA HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE SERVICES AND PRODUCTS, EITHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND NON-INFRINGEMENT OF THIRD PARTY RIGHTS. TDA DOES NOT WARRANT AGAINST INTERFERENCE WITH YOUR ENJOYMENT OF SERVICES AND PRODUCTS, THAT THE FUNCTIONS CONTAINED THEREIN, OR SERVICES AND PRODUCTS PERFORMED OR PROVIDED WILL MEET YOUR REQUIREMENTS, THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS IN THE SERVICES WILL BE CORRECTED. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY TDA OR ITS AUTHORIZED REPRESENTATIVE SHALL CREATE A WARRANTY. SHOULD THE SERVICES PROVE DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.`,
      },
      {
        title: "Limitation of Liability",
        des: [
          `TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL TDA BE LIABLE FOR PERSONAL INJURY, OR ANY INCIDENTAL, SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE SERVICES, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT OR OTHERWISE) AND EVEN IF TDA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU. `,
          `In no event shall TDA’s total liability to You for all damages (other than as may be required by applicable law in cases involving personal injury) exceed the amount of seventy-five dollars ($75.00). The foregoing limitations will apply even if the above stated remedy fails of its essential purpose.`,
          
        ],
    },
    {
        title: "INDEMNIFICATION",
        des:`You agree, at Your sole expense, to defend, indemnify and hold TDA, our independent contractors, service providers and consultants, and their respective directors, employees and agents, harmless from and against any and all actual or threatened suits, actions, proceedings (at law or in equity), claims, damages, payments, deficiencies, fines, judgments, settlements, liabilities, losses, costs and expenses (including, but not limited to, reasonable attorney fees, costs, penalties, interest and disbursements) caused by, arising out of, resulting from, attributable to or in any way incidental to Your conduct, Your violation of the Terms or Your violation of the rights of any third party.`,
      },
      {
        title: "Termination",
        des:`The Terms are effective until terminated by You or TDA. Your rights under these Terms will terminate automatically without notice from TDA if You fail to comply with any of the terms herein. Upon termination of the license, You shall cease all use of the Services, and destroy all copies, full or partial, of the App.`,
      },
      {
        title: "RELEASE",
        des:`If You have a dispute with one or more nightclubs, lounges or a merchant of a Product purchased through the App, You release TDA (and our officers, directors, agents, subsidiaries, joint ventures and employees) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.  FURTHERMORE, YOU HEREBY AGREE THAT YOU SHALL DRINK RESPONSIBLY AT ALL TIMES WHILE USING THE TDA APP, AND IF THERE ARE ANY ISSUES RELATED TO THE USE OF THIS TDA APP, YOU ACKNOWLEGDE THAT THE BAR, NIGHTCLUB, LOUNGE OR OTHER ESTABLISHMENT THAT SERVES ALCOHOLIC BEVERAGES TO YOU SHALL BE YOUR ONLY RECOURSE AND REMEDY AT LAW OR IN EQUITY. If You are a California resident, You waive California Civil Code §1542, which says: “A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if known by him must have materially affected his settlement with the debtor.”`,
      },
      {
        title: "EXPORT RESTRICTIONS",
        des:`You may not use or otherwise export or re-export the App except as authorized by United States law and the laws of the jurisdiction in which the App was obtained. In particular, but without limitation, the App may not be exported or re-exported (a) into any U.S. embargoed countries or (b) to anyone on the U.S. Treasury Department's list of Specially Designated Nationals or the U.S. Department of Commerce Denied Person’s List or Entity List. By using the App, You represent and warrant that You are not located in any such country or on any such list. You also agree that You will not use these products for any purposes prohibited by United States law, including, without limitation, the development, design, manufacture or production of nuclear, missiles, or chemical or biological weapons.`,
      },
      {
        title: "U.S. Government Rights",
        des:`The Licensed Application and related documentation are "Commercial Items", as that term is defined at 48 C.F.R. §2.101, consisting of "Commercial Computer Software" and "Commercial Computer Software Documentation", as such terms are used in 48 C.F.R. §12.212 or 48 C.F.R. §227.7202, as applicable. Consistent with 48 C.F.R. §12.212 or 48 C.F.R. §227.7202-1 through 227.7202-4, as applicable, the Commercial Computer Software and Commercial Computer Software Documentation are being licensed to U.S. Government end users (a) only as Commercial Items and (b) with only those rights as are granted to all other end users pursuant to the terms and conditions herein. Unpublished-rights are reserved under the copyright laws of the United States.`,
      },
      {
        title: "GOVERNING LAW AND VENUE",
        des:`The laws of the Commonwealth of Massachusetts, excluding its conflicts of law rules, govern these Terms and Your use of the Services. Your use of the Licensed Application may also be subject to other local, state, national, or international laws. You agree that any action at law or in equity arising out of or relating to these Terms shall be filed only in the state and federal courts located in the Commonwealth of Massachusetts and You hereby irrevocably and unconditionally consent and submit to the exclusive jurisdiction of such courts over any suit, action or proceeding arising out of these Site Terms.`,
      },
      {
        title: "Severability",
        des:`If any provision of these Terms shall be deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions.`,
      },
      {
        title: "CONTACT",
        des:`With questions about these Terms, You may contact TDA at 781-742-5506 or at the following email address: DrinkApp2020@gmail.com.`,
      },

      

  ];