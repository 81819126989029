import React from "react";
import drinklogo from "../Image/drinklogo.svg";
import { Flex, Image, Text } from "@chakra-ui/react";
import { ABOUT_US_DETAILS } from "../Component/constant";

export default function Terms() {
	return (
		<>
			<Flex
				bgColor="#480068"
				flexDirection="column"
				justify="center"
				alignItems="center"
				h="320px"
			>
				<Flex
					direction="column"
					alignItems="center"
					gap="20"
					justifyContent="center"
				>
					<Image
						src={drinklogo}
						flexShrink="0"
						h="163px"
						w={{ base: "130px", md: "187px" }}
					/>
					<Text
						color="white"
						fontWeight="700"
						fontSize="32px"
						fontFamily="sans-serif"
					>
						Terms and Conditions
					</Text>
				</Flex>
			</Flex>

			<Flex m=" 35px 70px ">
				<Flex w="full" direction="column">
					<Text
						fontSize="16px"
						fontFamily="Poppins"
						p="10px"
						fontWeight="bold
					"
					>
						Effective as of April 15, 2021
					</Text>
					<Text fontSize="16px" fontFamily="Poppins">
						PLEASE READ THE FOLLOWING TERMS OF SERVICE CAREFULLY. BY ACCESSING
						OR USING THE DRINK APP MOBILE PHONE APPLICATION AND ANY SERVICES
						PROVIDED BY US, YOU AGREE TO BE BOUND BY THESE TERMS OF SERVICE. IF
						YOU DO NOT AGREE TO ALL OF THESE TERMS OF SERVICE, YOU MAY NOT
						ACCESS OR USE THE SERVICES PROVIDED BY US.
					</Text>
					<Text fontSize="16px" fontFamily="Poppins">
						The Terms of Service (the “Terms”) apply when You view or use The
						Drink App’s (“TDA”, “we”, “our” or “us”) website located at
						www.drinkapp.us (the “Website”) or by accessing our mobile phone
						application called The Drink App (the “App”) on Your mobile device.
						The App, the Website, and any feature, tool, content, and service
						accessible via the Website or App are collectively referred to as
						the “Service.”
					</Text>
					{ABOUT_US_DETAILS.map((data) => (
						<>
							{" "}
							<Flex pb="10px" direction="column" pt="30px">
								<Text fontSize="20px" fontWeight="700">
									{data.title}
								</Text>
								{React.Children.toArray(
									typeof data.des != "string" &&
										data.des.map((para) => (
											<Text fontSize="16px" fontFamily="Poppins" mb="10px">
												{para}
											</Text>
										))
								)}
								{typeof data.des === "string" && (
									<Text fontSize="16px" fontFamily="Poppins">
										{data.des}
									</Text>
								)}
							</Flex>
						</>
					))}
				</Flex>
			</Flex>
		</>
	);
}
