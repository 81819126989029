import {
	Flex,
	Heading,
	Image,
	ListItem,
	Text,
	UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import drinklogo from "../Image/drinklogo.svg";
export default function Privacy() {
	return (
		<>
			<Flex
				bgColor="#480068"
				flexDirection="column"
				justify="center"
				alignItems="center"
				h="320px"
				// gap="45"
				// pt="35px"
			>
				<Flex
					direction="column"
					alignItems="center"
					gap="20"
					justifyContent="center"
					//  justify="center"
				>
					<Image
						src={drinklogo}
						flexShrink="0"
						h="163px"
						//   w="187px"
						w={{ base: "130px", md: "187px" }}
					/>
					<Text
						color="white"
						fontWeight="700"
						fontSize="32px"
						fontFamily="sans-serif"
					>
						Privacy Policy
					</Text>
				</Flex>
			</Flex>
			{/* <Flex backgroundColor="red" m=" 35px 70px "> */}
			<Flex flexDirection="column" m=" 35px 70px " gap="15px" bg="white">
				<Text fontSize="16px " fontFamily="Poppins" p="10px" fontWeight="bold">
					Effective Date: April 1, 2021
				</Text>
				<Text fontSize="16px" fontFamily="Poppins">
					This privacy policy (this “Policy”) explains how personal information
					is collected, used, and disclosed by The Drink App LLC (“TDA” or
					“we”). This Policy applies to consumer users (individually referred to
					as “you”) of TDA websites, the TDA mobile application (the “App”), and
					the services accessible through the Website or App (collectively, our
					“Sites”).
				</Text>
				<Text fontSize="16px" fontFamily="Poppins">
					We reserve the right to change this Policy at any time. We will notify
					you that changes have been made by indicating on this Policy the date
					it was last updated. We encourage you to review this Policy from
					time-to-time to make sure you understand how any personal information
					you provide to us or collected about you will be used.
				</Text>
				<Text fontSize="16px" fontFamily="Poppins">
					Other third parties, including without limitation, nightclubs and
					lounges that market and/or provide services on the Sites, and third
					party payment services integrated into the Sites, may also collect,
					use, and share information about you. This Policy does not cover such
					third parties or their services. For more information about
					third-party privacy practices, please contact them directly.
				</Text>
				<Heading fontSize="20px" fontFamily="Poppins" fontWeight="700">
					INFORMATION WE COLLECT
				</Heading>
				<Text fontSize="16px" fontFamily="Poppins">
					We collect information about you in various ways when you use our
					Sites. We use this information for a variety of reasons, including but
					not limited to providing the functionality and improving the quality
					of our Sites and personalizing your overall experience.
				</Text>
				<Heading fontSize="16px" fontFamily="Poppins" fontWeight="700">
					Active Collection.
				</Heading>
				<Text fontSize="16px" fontFamily="Poppins">
					We collect information from you when you use the Sites. For example,
					when you create an account on our Sites, we may ask you to provide
					your name, email address, postal address, phone number(s), and other
					information. You may choose not to provide certain personal
					information. However, if you decide to do so, you may not be able to
					take advantage of the Service.
				</Text>
				<Text fontSize="16px" fontFamily="Poppins">
					We collect financial information, such as your payment method (i.e.
					valid credit card number, type, expiration date or other financial
					information) only through a third party payment process integrated
					into our App. That information is collected and stored by our third
					party payment processing company (the “Payment Processor”), and the
					subsequent use and storage of information is governed by the Payment
					Processor’s applicable terms of service and privacy policy.
				</Text>
				<Heading fontSize="16px" fontFamily="Poppins" fontWeight="700">
					Passive Collection.
				</Heading>
				<Text fontSize="16px" fontFamily="Poppins">
					When you use the Service, some information is also automatically
					collected, such as your Internet Protocol (IP) address, your operating
					system, the browser type, the address of a referring website, and your
					activity on the Service. We also may automatically collect certain
					logistical information in server logs, including information about how
					you use various features of the Service and information about the
					number, frequency and length of each session.
				</Text>
				<Text fontSize="16px" fontFamily="Poppins">
					We also may automatically collect certain information through the use
					of “cookies.” Cookies are small data files stored on your hard drive
					at the request of a website. Among other things, cookies help us to
					improve the Service and your experience. If you wish to block, erase,
					or be warned of cookies, please refer to your browser manufacturer to
					learn about these functions. However, if you choose to remove or
					reject cookies, this could affect certain features or services made
					available on the Sites.
				</Text>
				<Text fontSize="16px" fontFamily="Poppins">
					Currently, we do not employ technology that recognizes “do-not-track”
					signals. We may engage third parties, such as analytics or marketing
					partners, who may collect information about your online activities
					over time and across different websites when you use our Sites.
				</Text>
				<Text fontSize="16px" fontFamily="Poppins">
					We may collect device-specific information (i.e. operating system
					version, your hardware model, unique device identifiers, and mobile
					network information including phone number). We may associate your
					device identifiers or phone number with your TDA account.
				</Text>
				<Text fontSize="16px" fontFamily="Poppins">
					We may use Web beacons or similar technology on the Sites. Web beacons
					are small, invisible graphic images that may be used on the Sites to
					collect certain information and monitor user activity, such as to
					count visits and understand usage effectiveness.
				</Text>
				<Text fontSize="16px" fontFamily="Poppins">
					Personal information will be stored on secured servers, and we may
					store and process personal information in the United States and other
					countries.
				</Text>
				<Heading fontSize="20px" fontFamily="Poppins" fontWeight="700">
					HOW WE USE INFORMATION WE COLLECT
				</Heading>
				<Text fontSize="16px" fontFamily="Poppins">
					We use personal information collected through our Sites for the
					purposes described in this Policy or disclosed to you on our Sites or
					otherwise in connection with our services. For example, we may use
					your information to:
				</Text>
				<UnorderedList>
					<ListItem>
						to request feedback and to otherwise contact you about your use of
						the Service;
					</ListItem>
					<ListItem>
						process and complete transactions in connection with the Service
						(e.g. to make premium services of nightclubs and lounges accessible
						to you);
					</ListItem>
					<ListItem>to monitor and analyze Site usage and trends;</ListItem>
					<ListItem>
						to respond to your emails, questions, comments, requests and
						complaints, and to provide customer service;
					</ListItem>
					<ListItem>
						to provide you with news and information about our or third-party
						events, offers, promotions and services we think interest you;
					</ListItem>
					<ListItem>
						to personalize and improve the Sites, and our users’ experiences
						with the Sites (such as providing content or features that match
						interests), and to increase the Sites’ functionality and user
						friendliness;
					</ListItem>
					<ListItem>
						to send you confirmations, updates, security alerts and support and
						administrative messages, and otherwise facilitate your use of, and
						our administration and operation of, the Sites;
					</ListItem>
					<ListItem>
						to notify you about important changes to the service; and
					</ListItem>
					<ListItem>
						for any other purpose for which the information was collected.
					</ListItem>
				</UnorderedList>
				<Heading fontSize="20px" fontFamily="Poppins" fontWeight="700">
					HOW WE SHARE INFORMATION
				</Heading>
				<Text fontSize="16px" fontFamily="Poppins">
					When you purchase access to premium services of nightclubs and lounges
					(the “Venues”) through our App, we provide your personal information
					to such Venues to facilitate your access to the premium services. TDA
					does not control the privacy practices of Venues. Please contact the
					Venue directly if you want to learn about its privacy practices
				</Text>
				<Text fontSize="16px" fontFamily="Poppins">
					We do not share your personal information with third parties other
					than as described above and as follows:
				</Text>
				<Text fontSize="16px" fontFamily="Poppins">
					We may share aggregate statistical data for the improvement of
					services offered by our Sites. We may share personal information with
					affiliates, third-party vendors, consultants, and other service
					providers who work for us. Such third-party vendors may include
					vendors who provide fraud detection services to us and other third
					parties.
				</Text>
				<Text fontSize="16px" fontFamily="Poppins">
					We share personal information with third parties who provide services
					to us, such as data collection, reporting, ad response measurement,
					and site analytics, as well as assistance with delivery of relevant
					marketing and advertising. These third parties may view, edit, or set
					their own cookies. We and our third-party service providers,
					advertisers, and/or partners may also place web beacons for these
					third parties. The use of these technologies by these third parties is
					subject to their own privacy policies and is not covered by this
					Policy. We may also share with third parties aggregated or
					de-identified information that does not identify you.
				</Text>
				<Text fontSize="16px" fontFamily="Poppins">
					We may disclose your personal information for the following reasons:
					(i) to comply with laws and to respond to lawful requests and legal
					process, (ii) to protect the rights and property of TDA, our agents
					and customers, and others, including to enforce our agreements,
					policies, and terms of use, and (iii) in an emergency to protect the
					personal safety of you, TDA customers, or any other person.
				</Text>
				<Heading fontSize="20px" fontFamily="Poppins" fontWeight="700">
					LINKS TO OTHER WEBSITES
				</Heading>
				<Text fontSize="16px">
					{" "}
					fontFamily="Poppins" The Sites may contain links to other websites.
					Any personal information you provide on the linked pages is provided
					directly to that third party and is subject to that third party’s
					privacy policy. This Policy does not apply to such linked websites,
					and we are not responsible for the content or privacy and security
					practices and policies of these websites or any other websites that
					are linked to/from the Sites. We encourage you to learn about their
					privacy and security practices and policies before providing them with
					personally identifiable information.
				</Text>
				<Heading fontSize="20px" fontFamily="Poppins" fontWeight="700">
					SECURITY OF YOUR PERSONAL INFORMATION
				</Heading>
				<Text fontSize="16px" fontFamily="Poppins">
					We always take reasonable measures to protect your personal
					information to prevent misuse and loss, as well as any unauthorized
					access, disclosure, alteration, and destruction. However, please be
					aware that despite our best efforts, no security measures are perfect
					or impenetrable. Therefore, no method of data transmission can
					guarantee against any interception or any other type of misuse. To
					assist with the protection of personal information, you must keep your
					password confidential and not disclose it to any other person. You are
					responsible for all uses of the Sites and our services by any person
					using your password. Please advise us immediately if you believe your
					password has been misused in any manner.
				</Text>{" "}
				<Heading fontSize="20px" fontFamily="Poppins" fontWeight="700">
					YOUR INFORMATION CHOICES AND CHANGES
				</Heading>
				<Text fontSize="16px" fontFamily="Poppins">
					In the event we decide to use your personal information for any
					purpose (other than as referenced in this Policy), we will offer you
					an effective way to opt out of the use of your personal information
					for those other purposes. You may opt out of receiving promotional
					emails from TDA by following the instructions in those emails. If you
					opt out, we may still send you non-promotional emails, such as emails
					about your TDA account or our ongoing business relations.
				</Text>{" "}
				<Heading fontSize="20px" fontFamily="Poppins" fontWeight="700">
					CONTACTING TDA
				</Heading>
				<Text fontSize="16px" fontFamily="Poppins">
					For questions about this Policy or accessing, changing, or deleting
					your personal information you may contact TDA at 781.742.5506 or at
					DrinkApp2020@gmail.com.
				</Text>
			</Flex>
			{/* </Flex> */}
		</>
	);
}
