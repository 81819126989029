import React from "react";
import {
	Flex,
	Image,
	Img,
	Text,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverArrow,
	Divider,
} from "@chakra-ui/react";
import drinklogo from "../Image/drinklogo.svg";
import google from "../Image/Googleplay.svg";
import app from "../Image/AppStore.svg";
import { Link } from "react-router-dom";
import facebooklink from "../Image/facebooklink.svg";
import instagramlink from "../Image/instagramlink.svg";
import twitterlink from "../Image/twitterlink.svg";

export default function Down() {
	return (
		<>
			<Flex
				direction="column"
				bgColor="#480068"
				minHeight="100vh "
				justifyContent="space-between"
			>
				<Flex
					textColor="white"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					width="100%"
					paddingTop="105px"
					m="auto"
				>
					<Text fontWeight="500" fontSize="32px" lineHeight="normal">
						Welcome to...
					</Text>
					<Image
						src={drinklogo}
						marginTop="30px"
						flexShrink="0"
						h="216px"
						w={{ base: "150px", md: "250px" }}
						marginBottom="60px"
					/>
					<Text marginBottom="29px" fontWeight="400" fontSize="24px">
						Download the App here!
					</Text>
					<Flex gap="30px" direction={{ base: "column", md: "row" }}>
						<Link to="https://apps.apple.com/us/app/drink-app/id1587599387">
							<Img src={app} w={{ base: "100px", md: "200px" }} />
						</Link>
						<Popover>
							<PopoverTrigger>
								<Img src={google} w={{ base: "100px", md: "200px" }} />
							</PopoverTrigger>
							<PopoverContent>
								<PopoverArrow />
								<PopoverHeader
									bgColor="white"
									color="#480068"
									alignItems="center"
									p="10px"
									fontWeight="bold"
									borderRadius="50px"
								>
									Coming Soon!
								</PopoverHeader>
							</PopoverContent>
						</Popover>
					</Flex>
					<Text marginTop="61px" fontWeight="400" fontSize="24px">
						Follow Us
					</Text>
					<Flex marginTop="30px" mb="50px" gap="32px" bottom="0">
						<Link to="https://www.instagram.com/drinkapp2020/">
							<Image src={instagramlink} h="32px" w="32px" flexShrink="0" />
						</Link>
						<Link to="https://twitter.com/drinkapp2020">
							<Image src={twitterlink} h="32px" w="32px" flexShrink="0" />
						</Link>
						<Link to="https://www.facebook.com/drinkapp2020">
							<Image src={facebooklink} h="32px" w="32px" flexShrink="0" />
						</Link>
					</Flex>
				</Flex>
				<Flex w="90%" m=" 0px auto" flexDirection="column">
					<Divider orientation="horizontal" border="1px solid #E19F00" />
					<Flex
						justifyContent="space-between"
						direction={{ base: "column", md: "row" }}
						p=" 5px 0px"
						width="100%"
						margin="auto"
					>
						<Flex>
							<Text
								fontWeight="400"
								fontSize={{ base: "12px", md: "16px" }}
								color="white "
								fontFamily="Poppins"
								marginLeft={{ base: "auto" }}
								marginRight={{ base: "auto" }}
							>
								© All Copyright 2023 by DRINK, LLC
							</Text>
						</Flex>

						<Flex justify="space-between">
							<Link to="/terms">
								<Text
									fontWeight="400"
									fontSize={{ base: "12px", md: "16px" }}
									color="white"
									fontFamily="Poppins"
								>
									Terms and Condition
								</Text>
							</Link>
							<Link to="/privacy">
								<Text
									fontWeight="400"
									fontSize={{ base: "12px", md: "16px" }}
									color="white"
									ml="30px"
									fontFamily="Poppins"
								>
									Privacy Policy
								</Text>
							</Link>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</>
	);
}
